<template>
    <v-container>
        <v-data-table
            :loading="!isDownloaded"
            :headers="headers"
            :items="tasks"
            class="elevation-2"
            :sort-desc="false"
            disable-pagination
            item-key="_id"
            hide-default-footer
        >
            <template v-slot:item.status="{ item }">
                <StatusChip :status="item.status" />
            </template>
            <template v-slot:item.actions="{ item, index }">
                <div class="d-flex align-center">
                    <v-btn
                        color="green"
                        class="mt-1 mb-1 white--text"
                        @click.stop="changeRoute(item._id)"
                        v-if="checkPreviousBtn(index)"
                    >
                        Zobacz
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="Certyfikat-ContentWriter"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
            class="showoff"
        >
            <section class="vue-html2pdf" slot="pdf-content">
                <div class="box">
                    <img class="bg" src="../../assets/cert/bg.svg" alt="" />
                    <div class="wrapper">
                        <div class="case">
                            <div class="header">
                                <img
                                    class="logo"
                                    src="../../assets/cert/logo.svg"
                                    alt=""
                                />
                                <div class="header-text">
                                    <p>{{ getDate(certDate) }}</p>
                                    <p>
                                        ID: KK{{certNum}}
                                    </p>
                                </div>
                            </div>
                            <div class="content">
                                <h1>Certyfikat ukończenia kursu</h1>
                                <h2>
                                    Dla: <strong>{{ name }}</strong>
                                </h2>
                                <p>
                                    Niniejszym potwierdzamy ukończenie
                                    <strong>60-godzinnego</strong><br />
                                    kursu korekty w organizacji Content Writer.
                                </p>
                                <p>Program szkolenia obejmował między innymi:</p>
                                <ul>
                                    <li>Praktyczną korektę artykułów oraz opisów</li>
                                    <li>Szczegółowe omówienie zasad SEO</li>
                                    <li>Optymalizację treści pod kątem marketingowym</li>
                                </ul>
                                <p>
                                    Jednocześnie zaświadczamy, że absolwent
                                    cechuje się dużą<br />
                                    komunikatywnością, zaangażowaniem oraz
                                    sumiennością<br />
                                    w wykonywanych zadaniach.
                                </p>
                            </div>
                            <div class="sign">
                                <div class="sign-content">
                                    <div>
                                        <p>Bartosz Ciesielski</p>
                                        <p>CEO</p>
                                    </div>
                                </div>
                            </div>
                            <div class="sign-img">
                                <img src="../../assets/cert/sign.png" alt="" />
                            </div>
                            <div class="bottom">
                                <p>
                                    Certyfikat autoryzowany przez Content Writer sp. z o.o., z siedzibą na al. Marcinkowskiego 13/16, 61-827 Poznań, NIP 7792567297, REGON 527809297.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
        <v-btn
            @click="makeCert"
            color="green"
            class="mt-5 mr-3 mb-1 white--text"
            v-if="checkIfFinished && this.tasks.length > 0"
        >
            Pobierz certyfikat
        </v-btn>
    </v-container>
</template>

<script>
import StatusChip from "@/components/molecules/StatusChip.vue";
import VueHtml2pdf from "vue-html2pdf";
import io from "socket.io-client";

const token = localStorage.getItem('user');

export default {
    components: {
        StatusChip,
        VueHtml2pdf,
    },
    computed: {
        id() {
            return this.$store.getters.getUserID;
        },
        checkIfFinished() {
            for (let i = 0; i < this.tasks.length; i++) {
                const element = this.tasks[i];
                if (element.status != "Zakończone") {
                    return false;
                }
            }

            return true;
        },
        userId(){
            return this.$store.getters.getUserID;  
        },
    },
    data() {
        return {
            socket: io(this.url),
            tasks: [],
            name: null,
            isDownloaded: false,
            certNum: null,
            cert: null,
            certDate: null,
            headers: [
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "title",
                },
                { text: "Status", value: "status", sortable: true },
                { text: "Akcje", value: "actions", sortable: false },
            ],
        };
    },
    methods: {
        getCert() {
            this.$axios
                .get(`${this.url}api/certificate-date/${this.userId}`, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    console.log(resp.data);
                    this.cert = resp.data;
                    if(this.cert != null){
                        this.certNum = resp.data.numCert;
                        this.certDate = resp.data.certDate;
                    } 
                });
        },
        makeCert(){
            if(this.cert == null){
                this.$axios
                    .post(`${this.url}api/certificate-date/`, {
                        id: this.userId,
                        numCert: Math.floor(Math.random() * 90000) + 10000
                    }, { headers: { 'x-access-token': token } })
                    .then((res) => {
                        this.cert = res.data;
                        this.certNum = this.cert.numCert;
                        this.certDate = this.cert.certDate;
                        console.log('RESULTAT:', res.data);
                        if(res.data.certificateGenerated != true){
                            console.log("Wykonuje update")

                            this.$axios.put(`${this.url}api/apprentice/certificate-course/update/${this.userId}`, 
                                { certificateGenerated: true },
                                { headers: { 'x-access-token': token } }
                            )
                            .then(updateResp => {
                                console.log("Certificate status updated to generated", updateResp.data);
                            })
                            .catch(updateErr => {
                                console.error("Error updating certificate status", updateErr);
                            });

                        }

                        this.generateReport(); 
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }else{           
                this.generateReport();                
            }
        },
        getTask() {
            this.$axios
                .get(`${this.url}api/course/${this.id}`, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    this.tasks = resp.data.tasks;
                    this.name = resp.data.name;
                    this.isDownloaded = true;
                });
        },
        changeRoute(_id) {
            this.$router.push(`${this.id}/task/${_id}`);
        },
        generateReport() {
            this.$axios
                .get(`${this.url}api/course/certificate/${this.id}`, { headers: { 'x-access-token': token } })
                .then((resp) => {

                    if(resp.data.certificateGenerated == false){

                        this.$axios.put(`${this.url}api/course/certificate/update/${this.id}`, 
                            { certificateGenerated: true },
                            { headers: { 'x-access-token': token } }
                        )
                        .then(updateResp => {
                            console.log("Certificate status updated to generated", updateResp.data);
                        })
                        .catch(updateErr => {
                            console.error("Error updating certificate status", updateErr);
                        });

                    }

                    this.$refs.html2Pdf.generatePdf();
                });
        },
        getDate() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear();

            today = dd + "." + mm + "." + yyyy;
            return today;
        },
        checkPreviousBtn(index){
            if(index-1 != -1){
                if(this.tasks[index-1].status == 'Zakończone'){
                    return true;
                }
                return false;
            }else{
                return true;
            }
        }
    },
    created() {
        this.getTask();
        this.getCert();

        this.socket.on("newTaskAprrenticeAdded", () => {
            this.getTask();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getTask();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getTask();
        });
    },
};
</script>

<style lang="scss" scoped>
@import url("https://p.typekit.net/p.css?s=1&k=rlz0bbl&ht=tk&f=34863.34864.34866&a=14622484&app=typekit&e=css");

@font-face {
    font-family: "arboria";
    src: url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
            format("woff"),
        url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "arboria";
    src: url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
            format("woff"),
        url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "arboria";
    src: url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
            format("woff2"),
        url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
            format("woff"),
        url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
            format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}
.vue-html2pdf {
    font-family: "arboria", sans-serif;

    .tk-arboria {
        font-family: "arboria", sans-serif;
    }
    .box {
        position: relative;
        // height: 892px;
        width: 1122px;
        background-color: white;
    }
    .case {
        width: 750px;
    }
    .wrapper {
        position: relative;
        padding: 60px 178px 30px 182px;
    }
    .bg {
        position: absolute;
        left: 0;
        top: 39%;
        transform: translateY(-50%);
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        width: 750px;
        .logo {
            width: 250px;
        }
    }
    .header-text p {
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        color: #9a9a9a;
        margin: 0;
    }
    .header-text p:first-of-type {
        margin-bottom: 6px;
    }
    .content h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: #17223a;
        margin: 0 0 25px 0;
    }
    .content h2 {
        font-size: 19px;
        line-height: 22px;
        font-weight: 400;
        color: #394c61;
        margin: 0 0 29px 0;
    }
    .content h2 strong {
        font-weight: 500;
    }
    .content p,
    li {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #394c61;
    }
    .content p {
        margin: 0 0 25px 0;
    }
    .content p strong {
        font-weight: 500;
    }
    .content ul {
        margin: 0 0 25px 0;
        padding: 0;
    }
    .content li {
        margin-left: 15px;
    }
    .content li::marker {
        color: #76c981;
        font-size: 14px;
    }
    .sign {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
    }
    .sign-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .sign-content p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #394c61;
        margin: 0 0 4px 0;
    }
    .bottom p {
        font-size: 8px;
        line-height: 9px;
        font-weight: 400;
        color: #394c61;
        margin: 10px 0 0 0;
        margin-top: 30px;
    }
    .sign-img {
        display: flex;
        justify-content: flex-end;
    }
    .sign-img img {
        margin-right: 24px;
    }
    .header,
    .content,
    .sign,
    .sign-img,
    .bottom,
    .case {
        position: relative;
        z-index: 2;
    }
}
.showoff {
    display: none;
}
</style>
